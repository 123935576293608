<template>
  <b-row no-gutters>
    <b-col md="3" xl="2">
      <b-navbar toggleable="sm" type="dark" variant="dark">
        <b-navbar-brand href="/admin/dashboard">
          <img id="navbar-brand-logo" src="/assets/images/textree_logo_blue.png" alt="">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="/admin/dashboard" :active='$route.path.indexOf("/admin/dashboard") == 0'>Dashboard</b-nav-item>
            <b-nav-item href="/admin/media" :active='$route.path.indexOf("/admin/media") == 0'>Media</b-nav-item>
            <b-nav-item href="/admin/portfolio" :active='$route.path.indexOf("/admin/portfolio") == 0'>Portfolio</b-nav-item>
            <b-nav-item href="/admin/blogs" :active='$route.path.indexOf("/admin/blogs") == 0'>Blogs</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-col>
    <b-col md="9" xl="10">
      <router-view/>
    </b-col>
  </b-row>
</template>

<script>
import '@/plugins/bootstrap-vue'
export default {
}
</script>

<style scoped>
img#navbar-brand-logo {
  max-height: 32px;
}

@media (min-width: 576px) {
  .no-gutters {
    height: 100%;
  }
  .navbar-expand-sm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  .navbar-expand-sm .navbar-collapse {
    align-items: stretch;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: column;
  }
  img#navbar-brand-logo {
    max-width: 100%;
  }
}

</style>
<style>
  #plusfriend-chat-button {
    display: none;
  }
</style>
